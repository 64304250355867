

:root {
  --primary-color: #5792c9;
  /* --body-bg-color: #f5f5f5; */
  --text-primary-color: #0f0f12;
  --text-secondary-color: rgba(15, 15, 18, 0.75);
  --button-text-color: #fff;
  --icon-color: #5792c9;

  --in-card-spacer-y: 1rem;
  --in-card-spacer-x: 1rem;
}
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
.full-height-container {
  height: 100vh;
}

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');
 

/* Noto Nastaliq Urdu */
@font-face {
  font-family: 'Alvi Nastaleeq Regular';
  src: url('../fonts/NotoNastaliqUrdu-VariableFont_wght.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-size: 11px !important;
  line-height: 28px;
}
 
body {
  font-family: 'Manrope', Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  color: #595959 !important;
}
html{
  font-family: 'Manrope', Arial, sans-serif;
}
.font-family-urdu,
.font-family-urdu div,
.font-family-urdu input,
.font-family-urdu label {
  font-family: 'Alvi Nastaleeq Regular' !important;
  font-size: 11px !important;
  line-height: 28px;
}

#editable{
  font-family: 'Alvi Nastaleeq Regular' !important;
}


.overlay-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.audio-player{
  width: 100% !important;
    height: 30px;
}

/* spacing */
.spacing > * + * {
  margin-top: 16px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-spacing {
  display: flex;
  flex-direction: column;  
  gap: 16px; 
}

@media (min-width: 600px) {
  .row-spacing {
    flex-direction: row;
  }
}

 /* login */
 .login-text{
  font-size: 29px;
  font-weight: 400;
 }

.login-img-min-max {
  min-width: 150px;  
  max-width: 200px;   
}
.login-page-button{
  border-radius: 8px !important;
    height: 40px;
    text-transform: capitalize !important;
    font-size: 16px !important;
} 

/* Profile */
.header-title{
  color: #595959;
  font-size: 24px;
  font-weight: 600;
  margin-top: 18px;
}

.profile-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px; 
  background-color: #f8f8f8;
  
}

.profile-avatar {
  margin-right: 10px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.user-name {
  font-weight: 700;
  margin-bottom: -1px;
  color:   #0f0f12;
  font-size: 13px;  
}

.user-role {
  color: #acacac;  
  font-size: 12px;  
}

.chevron-icon {
  font-weight: bold;
  margin-left: 20px;
  margin-right: 5px;
  font-size: 10px;  
  color: #0f0f12;  
}


.profile-container-view{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* background-color: #f9f9f9; */
  padding: 15px;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}
profile-avatar {
  margin-bottom: 1rem;
}

.profile-info-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-name-view {
  font-weight: 600;
  font-size: 17px;
  margin-top: 5px;
}

.user-email-view {
  color: #acacac;
  font-size: 10px;
  margin-top: -1px;
}

.user-role-view {
     /* color: #595959; */
     background-color: #f8f8f8;
     border-radius: 5px;
     padding: 3px 27px;
     font-size: 10px;
     margin-top: 5px;
}






.notification-container {
  margin-top: 8px;
  border-radius: 5px;
  background-color: #fffaf1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 40px;
  height: 36px;
}

.notification-bell {
  width: 20px;
  height: 20px;
  color: #ffa412b0 !important;
}

.notification-dot{
  padding: 3px;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 2px;
  height: 2px;
  background-color: #eb5757d4;
  border-radius: 50%;
}

 

/* dashboard */

.dashboard-card{
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 15px;
  /* text-align: "center", */
  box-shadow: none;
  border: 1px solid #cecece;
}
 
.dashboard-card-title{
  opacity: 0.9;
  font-size: 14px;
  font-weight: 500;
}
.dashboard-card-count{
  font-size: 22px;
  font-weight: 600;
  margin-top: 5px;
}


/* Styles for large screens */
/* @media (min-width: 992px) { */
@media (min-width: 768px) and (max-width: 991px) {
  .dashboard-card-title {
      font-size: 0.9vw;
  }

  .dashboard-card-count {
      /* font-size: 20px; */
      font-size: 2vw;  
  }
}
@media (min-width: 992px) and (max-width: 1317px) {
  .dashboard-card-title {
      font-size: 0.7vw;
      /* font-size: 14px; */
  }

  .dashboard-card-count {
    font-size: 1.5vw;  
    /* font-size: 22px; */
  }
}
 
/* Crops */

.text-uppercase{
  text-transform: uppercase;
}

.crops-active-status{
  color: #1bc28b;
  border: 1px solid #1bc28b;
  border-radius: 3px;
  text-align: center;
  padding: 3px 15px;
}
.crops-inactive-status{
  color: #ee2e30;
  border: 1px solid red;
  border-radius: 3px;
  text-align: center;
  padding: 3px 9px;
}
 

.tehsil-edit-icon svg{
  color: #5792c9;
}
.tehsil-delete-icon svg{
  color: #ee2e30;
}
.tehsil-list span{
  font-size: 14px !important;
  padding-left: 5px;
  /* font-family: 'Manrope'; */
}
.tehsil-list-popup-hide{
opacity: 0.0;
/* display: none; */
}

/* Table */
.table-single-cell{
  /* font-family: 'Manrope' !important; */
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 13px !important;

}

.table-container{
  border-radius: 20px !important;
  border: 1px solid #c2c8cd !important;
  box-shadow: none !important;
}
.table-heading{
  background-color: #eef4f9;
}
 
.table-heading-text{
  color: #5792c9 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.table-search{
  margin-top: 5px;
  margin-right: -10px;
  margin-bottom: -15px;
  border-radius: 10px !important;
  background-color: #ffffff;
  border: 1px solid #c2c8cd !important;
}

@media (max-width: 599px) {
  .table-search{
    margin-top: 10px;
    margin-right: 5px;
    margin-bottom: -15px;
    border-radius: 10px !important;
    background-color: #ffffff;
    border: 1px solid #c2c8cd !important;
  }
}

.table-menu-more-option{
  color: #595959!important;
}


.table-menu-more-option svg {
  color: #595959 !important;
}
 
.menus-list svg {
  color: #acacac !important;
}
 
.menuActive svg {
  color: var(--icon-color) !important;
}

.table-menu-more-option-dropdown svg {
  color: var(--icon-color) !important;
}

.table-image-with-name{
  margin-right: 15px;
  border-radius: 10px!important;
  background-color: #fafafa !important;
  color: #595959 !important;
}

.knowledge-bank-table-image{
  margin-right: 15px;
  border-radius: 5px!important;
  background-color: #fafafa !important;
  color: #595959 !important;
}

.knowledge-bank-type-avarar{
  margin-right: 15px;
  border-radius: 3px !important;
  background-color: #5792c9 !important;
  color: #ffffff !important;
}

/* Popover */
.custom-popover-container{
  padding: 25px 30px;
  display: flex;
  flex-direction: column;
  max-height: 90vh!important;
 
}

.scrollable-content {
  padding: 0px 10px;
  overflow-y: auto;
  flex: 1;
}
.popover-mid-container{
  margin-top: 5px;
  margin-bottom: 5px;
}

.custom-popover{
  margin-bottom: -8px;
}
 
.custom-popover-cross{
  padding: 9px;
  background-color: #f8f8f8;
  border-radius: 50%;
  margin-left: 15px;
}
.custom-popover-cross svg {
  color: #595959 !important;
}

.custom-popover-title h4{
  font-size: 20px!important;
}

.add-button svg {
  color: #ffffff !important;
}

 
 
.popover-cancel-button{
  background-color: #f8f8f8 !important;
  color: #8a8a8a !important;
  margin-left: 10px !important;
  text-transform: capitalize !important;
  font-size: 17px !important;
  padding: 0px 18px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
}
.popover-apply-button{
    /* border-radius: 7px !important; */
    margin-left: 10px !important;
    text-transform: capitalize !important;
    font-size: 17px !important;
    padding: 5px 20px !important;
    box-shadow: none !important;
    border-radius: 8px !important;
    min-width: 90px !important;
}
.popover-active-button{
  width: 105px !important;
  text-align: center !important;
  background-color: #f4fcf9 !important;
  color: #1bc28b !important;
  /* margin-left: 10px !important; */
  text-transform: capitalize !important;
  font-size: 17px !important;
  padding: 4px 21px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border-color: #1bc28b !important;
}
.popover-inactive-button{
  width: 105px !important;
  text-align: center !important;
  background-color: #fcf4f4 !important;
  color: #c21b1b !important;
  /* margin-left: 10px !important; */
  text-transform: capitalize !important;
  font-size: 17px !important;
  padding: 4px 14px !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border-color: #c21b1b !important;
}

.font-family{
   font-family: 'Manrope'!important;
}

.delete-confirm-text{
  color: #5b94ca !important;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px !important;
    margin-right: 2rem !important;
    margin-left: 2rem !important;
}
.delete-cancel-button{
  color: #595959!important;
  padding: 5px 25px !important;
}
.delete-confirm-button{
  background-color: #ee2e30 !important;
  color: "white";
  padding: 5px 25px !important;
}

 


.tehsil-label{
  margin-top: -10px;
  margin-bottom: 15px;
}


.company-city{
  border: 1px solid #5792c9;
  background-color: #e5eef6;
  color: #5792c9;
  padding: 2px 10px;
  border-radius: 50px;
  margin-right: 2px;
  min-width: 50px;
  text-align: center;
}
.multi-city-select-chip{
  border: 1px solid #5792c9 !important;
  background-color: #e5eef6 !important;
  color: #5792c9 !important;
  height: 24px !important;
}

.multi-category-select-chip{
  border: 1px solid #5792c9 !important;
  background-color: #e5eef6 !important;
  color: #5792c9 !important;
  height: 24px !important;
}

 

.MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent!important;
  color: #5792c9 !important;
  font-size: 15px!important;
  cursor: pointer!important;
}

.company-city-count{
  color: #5792c9 !important;
}

.company-url-preview{
  color: #5792c9 !important;
  text-decoration: none;
}
 
.agri-consultancy-chip{
  border: 1px solid #5792c9;
  background-color: #e5eef6;
  color: #5792c9;
  padding: 2px 10px;
  border-radius: 50px;
  margin-right: 2px;
  min-width: 50px;
  text-align: center;
}
.agri-consultancy-chip-count{
  color: #5792c9 !important;
}

.sales-market-chip {
  border: 1px solid #5792c9;
  background-color: #e5eef6;
  color: #5792c9;
  padding: 2px 10px;
  border-radius: 50px;
  margin-right: 2px;
  min-width: 50px;
  text-align: center;
}
.sales-market-count{
  color: #5792c9 !important;
}


 /* Text Field */
.text-field-border{
  border-radius: 10px!important;
}
.capitalized{
  text-transform: capitalize!important;
}
 

.apex-chart{
   

  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 15px;
  /* text-align: "center", */
  box-shadow: none;
  border: 1px solid #cecece;

}

.pointer {
  cursor: pointer;
}

 
 




/* Old Data */

/* convert 5792c9 to 5792c9 */

/* @font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("../fonts/Manrope-VariableFont_wght.ttf") format("ttf");
} */




 

 


/* 100 to 700 */
 
 
@media (min-width: 992px) {
  .carousel-inner {
    position: absolute !important;
    bottom: 0 !important;
  }
}

.div-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000005a;
}

.carousel-inner h5 {
  font-size: 1.25rem !important;
}

.search-widget-icon {
  color: #76797e;
  font-size: 16px;
  left: 11px;
  position: absolute;
  top: 12px;
  z-index: 10;
}

.search_icon {
  color: #76797e;
  font-size: 16px;
  left: 11px;
  position: absolute;
  top: 12px;
  z-index: 10;
}

.user-name-text {
  color: var(--text-primary-color);
}

@media (min-width: 767px) {
  .user-name-text {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .user-name-text {
    padding: 5px 2px;
  }
}

.dropdown-item {
  color: #0f0f12 !important;
  font-size: 15px !important;
}

.dropdown-item i,
.dropdown-item svg {
  font-size: 14px !important;
}

.popover_item {
  position: absolute;
  right: 4px;
  top: 60px;
}

.popover_item1 {
  position: absolute;
  right: 31px;
  top: 22px;
}

.notifi_num {
  position: absolute;
  left: 22px;
  bottom: 20px;
  color: white;
  background: red;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  text-align: center;
  font-size: 13px;
}

.menu-title {
  color: #919da9 !important;
  cursor: default;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.menu-title span {
  display: inline-block;
  padding: 12px 20px;
  color: #919da9 !important;
}

.navbar-nav .nav-sm .nav-link {
  color: #6c6f73 !important;
  white-space: none;
  cursor: pointer;
}

/* .navbar-nav .nav-link.active {
  background: linear-gradient(90deg, rgba(66, 138, 121, 0.1) 30%, transparent);
  color: #5792c9 !important;
} */

.sidebar-text{
  padding-left: 15px;
}

.menus-list {
  color: var(--text-primary-color) !important;
}

.menuActive {
  /* background: linear-gradient(90deg, rgba(66, 138, 121, 0.1) 100%, transparent); */
  background: linear-gradient(90deg, rgba(221, 233, 244, 0.7) 100%, transparent);

  

  /* background: #5792c9; */
  background-color: #5792c9;
  color: #5792c9 !important;
}

.menuActive .child_dot:before {
  background-color: #5792c9;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link a:hover,
.navbar-nav .nav-link span:hover {
  color: #5792c9 !important;
}

.navbar-nav .nav-link.active span,
.navbar-nav .nav-link.active i,
.navbar-nav .nav-link.active svg {
  color: #5792c9 !important;
}

.navbar-nav .nav-link i {
  display: inline-block;
  font-size: 20px;
  line-height: inherit;
  min-width: 1.85rem;
}

.navbar-nav .nav-link {
  align-items: center;
  color: #5d6064;
  color: var(--in-vertical-menu-item-color);
  display: flex;
  /* font-family: DM Sans, sans-serif; */
  font-size: 0.969rem;
  padding: 0.531rem 1.5rem;
}

.nav-link i,
.nav-link svg {
  font-size: 20px !important;
}

.navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  color: #919da9;
  content: ">";
  display: block;
  font-size: 0.813rem;
  font-weight: 900;
  margin-left: auto;
  position: absolute;
  right: 20px;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.menuActive:before {
  background-color: #5792c9;
  border-radius:  0px  50%  50%  0px;
  content: "";
  height: 40px;
  position: absolute;
  width: 3px;
  left: 0;
}

.child_dot:before {
  border: 1px solid #6c6f73;
  border-radius: 50%;
  content: "";
  height: 7px;
  transition: all 0.4s ease-in-out;
  width: 7px;
  margin-right: 12px;
  margin-left: 30px;
}

.menus-list {
  width: 100%;
}

.menus-list span {
  font-size: 14px !important;
}

.child_icon i,
.child_icon svg {
  font-size: 12px !important;
}

.page-title-box .h4,
.page-title-box h4 {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 25px !important;
}

.card {
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
  border: none !important;
}

.btn {
  box-shadow: none;
}

.card-header {
  background-color: #fff !important;
}

.card-title {
  font-size: 18px;
  margin: 0 0 7px;
}


 

.fs-14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-22 {
  font-size: 22px;
}

.fs-17 {
  font-size: 17px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.grafh_pop {
  position: absolute;
  right: 34px;
  top: 40px;
}

.dropdown-menu {
  border: none !important;
  box-shadow: 1px 1px 4px 1px #c1c1c1 !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: #f8f9fa !important;
}

@media (min-width: 992px) {
  .mini-widget {
    border-right: 1px solid #e9ebec !important;
  }
}

@media (max-width: 991px) {
  .mini-widget {
    border-bottom: 1px solid #e9ebec !important;
    margin-bottom: 15px;
  }
}

.text_overview span {
  font-size: 0.704rem !important;
}

.mini-widget:last-child {
  border: none !important;
}

.custom-vector-map svg path {
  fill: #f5f5f5 !important;
}

.text-truncate span {
  font-size: 0.704rem !important;
}

.table th {
  font-size: 14px !important;
}

.table p,
.table td {
  color: #0f0f12 !important;
}

.card-header {
  padding: 1rem !important;
}

.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.dropdown_btn {
  background-color: #477bf92b;
  color: #fff;
  height: 20px;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 3px;
}

.dropdown_btn i {
  color: #477bf9;
}

.header-profile-user {
  height: 36px;
  width: 36px;
}

.footer {
  background-color: #fff;
  color: #98a6ad;
  left: 260px;
  padding: 20px 0.75rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.min-height-75 {
  min-height: 75vh;
}

.apexcharts-toolbar,
.apexcharts-legend {
  display: none !important;
}

.btn-soft-info {
  background-color: rgba(80, 176, 243, 0.1);
  font-size: 12px;
  color: #489edb;
}

.btn-soft-info:hover,
.btn-soft-info:active {
  background-color: #4496cf !important;
  color: #fff !important;
}

.btn-info {
  background-color: #4496cf;
  font-size: 12px;
  color: #fff !important;
}

.btn-info:hover,
.btn-info:active {
  background-color: #4496cf !important;
  color: #fff !important;
}

.sales-history,
.structure-list {
  padding: 10px 0;
}

.bg_primary {
  background-color: var(--primary-color) !important;
}

 

.invoice_para p {
  margin-top: 20px;
}

.btn-light {
  background-color: #f5f5f5;
  color: #0f0f12 !important;
}

.avatar-sm {
  height: 2.6rem;
  width: 2.6rem;
}

.avatar-title {
  background-color: #5792c9;
  color: #fff;
  min-width: 35px;
  min-height: 35px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-success-subtle {
  background-color: #e9dcef !important;
}

.nav-item a {
  height: 47px !important;
}

@media (max-width: 599px) {
  .footer {
    left: 0 !important;
    position: relative !important;
    bottom: -30px !important;
  }
}

.bg-light-subtle {
  background-color: #f5f5f52e !important;
}

.notification_menu {
  height: 330px;
  overflow: auto;
}

.search_input input {
  box-shadow: none !important;
  outline: none !important;
  background-color: #f5f5f5 !important;
  color: #0f0f12;
  border: none !important;
  min-height: 40px;
  font-size: 16px !important;
}

.search_input input {
  padding: 0px 0px 0px 32px !important;
  width: 40% !important;
}

.search_input_table input {
  padding: 4px 20px 4px 38px !important;
  box-shadow: none !important;
  outline: none !important;
  background-color: #fff !important;
  color: #0f0f12;
  border: none !important;
  min-height: 40px;
  font-size: 16px !important;
}

.search_input_table input:focus {
  border: 1px solid var(--primary-color) !important;
}
 


/* 700 + to end */
 
 

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

.file_input_name {
  box-shadow: none;
  border: 1px solid #00000038;
}

.actvie_crumbs {
  color: var(--primary-color) !important;
  /* font-weight: 600 !important; */
}

.alert_danger {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 7px 10px;
}

.alert_danger i {
  cursor: pointer;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

.special-label {
  display: none;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 40px !important;
  font-size: 16px !important;
}

.react-tel-input .form-control:focus {
  outline: 0;
  box-shadow: none !important;
  border: 2px solid #5792c9 !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.react-tel-input .country-list {
  width: 270px !important;
}

.react-tel-input .country-list .search-box {
  margin-left: -3px !important;
  height: 30px !important;
  width: 100% !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

input:-webkit-autofill {
  background-color: transparent !important;
}

/* edit profile screen  */
.profile-cards {
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  cursor: pointer;
  transition: all 0.5s;
}

.cards-edit {
  border: none;
  box-shadow: 0 0 2px 0 rgb(0 3 82 / 24%), 0 16px 32px -4px rgb(0 3 82 / 24%);
  transition: all 0.5s;
}

.edit-profile-icon-position {
  position: absolute !important;
  right: -10px;
  top: 66px;
}

.edit-profile-icon img {
  border-radius: 50px;
}

.edit-profile-icon {
  position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.profile_avatar svg {
  color: #00000070 !important;
}

.succeeded_bg {
  color: #046307 !important;
  fill: #046307 !important;
  background: #0463073d !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.payment_failed_bg {
  color: #c62828 !important;
  fill: #c62828 !important;
  background: #d32f2f29 !important;
  padding: 8px;
  border-radius: 5px;
  font-size: 12px;
}
.Initiated_bg {
  color: #fff !important;
  fill: #fff !important;
  background: #808080d9 !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.refunded_bg {
  color: #117e99 !important;
  fill: #117e99 !important;
  background: #117e9933 !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.canceled_bg {
  color: #e4d612 !important;
  fill: #e4d612 !important;
  background: #e4d6122d !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}
.pending_bg {
  color: rgb(156, 39, 176) !important;
  fill: rgb(156, 39, 176) !important;
  background: rgba(155, 39, 176, 0.178) !important;
  padding: 8px;
  font-size: 12px;
  border-radius: 5px;
}


 
 

.tox-statusbar__branding {
  display: none !important;
}

.div-style {
  border-bottom: 1px solid #cacaca;
  padding: 8px;
}

.btn-primary svg {
  color: #fff !important;
}

.helper_text {
  color: black !important;
  font-size: 0.75rem !important;
}

.fixed_header {
  position: fixed;
  z-index: 999;
  width: 350px;
}

@media (max-width: 400px) {
  .fixed_header {
    width: 250px;
  }
}

.fixed_button {
  bottom: 30px;
  position: fixed;
  right: 20px;
  z-index: 1;
}

.pending-ticket {
  background-color: #ffc107;
  color: white !important;
  width: 80px;
}

.answer-ticket {
  background-color: #007bff;
  color: white !important;
  width: 80px;
}

.solved-ticket {
  background-color: #28a745;
  color: white !important;
  width: 80px;
}

.trash-ticket {
  background-color: #ff0000;
  color: white !important;
  width: 80px;
}

.sidebar-search-input {
  width: 100%;
  border: solid 1px var(--primary-color);
  height: 35px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  padding-left: 35px;
  color: var(--text-primary-color);
  outline: none !important;
}

.sidebar-search-box {
  position: relative;
}

.sidebar-search-icon {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 16px;
}

.upload-button label {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 76px;
  width: 76px;
  justify-content: center;
  padding: 15px;
  text-align: center !important;
}

.upload-button svg {
  color: #fff !important;
}

.preview {
  position: relative;
}

.preview img {
  border-radius: 5px;
  display: inline;
  height: 76px;
  margin-right: 10px;
  width: 65px;
}

.preview span {
  position: absolute;
  right: 3px;
  top: -32px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}

/* scrollbar */

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #438a7994;
}

::-webkit-scrollbar-thumb:hover {
  background: #438a7994;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:active {
  background: #666;
}

/* scrollbar */

.user_profile .profile_img {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}

.del_img_icon {
  position: relative;
  display: inline-block;
}

.del_img_icon span {
  position: absolute;
  right: -6px;
  top: -4px;
  font-size: 10px;
  font-weight: 500;
  height: 15px;
  width: 15px;
  background-color: red;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  z-index: 1;
}

.del_img_icon img {
  height: 50px !important;
  border: 1px solid #5792c9;
}

.carousel-inner h5,
.carousel-inner p {
  color: #fff !important;
}

.profile-card h6 {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.57;
}
.profile-card h3 {
  font-size: 20px;
}

.fw-14 {
  font-size: 14px !important;
}

.client_profile h6 {
  font-weight: 600 !important;
  margin-bottom: 0;
  font-size: 14px;
  margin-top: 2px;
}

.text_muted {
  opacity: 0.8;
  font-size: 14px;
}

#notistack-snackbar svg {
  color: #fff !important;
}

.m_top {
  margin-top: 2px;
}

.menu-background svg {
  cursor: pointer;
  font-size: 25px;
  background-color: #477bf91a;
  padding: 3px;
  border-radius: 3px;
}

.menu-background svg:hover {
  background-color: #4496cf !important;
  color: #fff !important;
}

.gold_border {
  border: 1px solid goldenrod;
  border-radius: 10px;
}

.pagination-style {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

@media (max-width: 1083px) {
  .pagination-style {
    margin-top: 10px;
  }
}

.filter_btn {
  position: absolute;
  bottom: 20px;
}

.btn-danger {
  min-width: 124px !important;
}

.clearIcon-button {
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  position: absolute;
  text-align: center;
  width: 20px;
  z-index: 1;
  border-radius: 10px;
}
.clearIcon-size {
  background-color: red !important;
  color: rgb(255, 255, 255) !important;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  padding: 2px 6px;
  position: absolute;
  top: -10px;
  cursor: pointer;
  font-weight: 600;
}
.navItems_search {
  margin-top: -20px;
}
.custom-textfield {
  position: relative;
  margin-bottom: 16px;
}

.label {
  position: absolute;
  top: -8px;
  left: 8px;
  background-color: white;
  padding: 0 4px;
  font-size: 12px;
  color: #000000 !important;
}

.title {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  width: 105%;
  min-height: 40px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.check-box-access {
  padding-left: 45px;
}
.css-fpqqwi-MuiCardContent-root:last-child {
  padding-bottom: 0px;
}

/* TagsInput */

.react-tagsinput {
  border-radius: 5px;
}

.react-tagsinput--focused {
  border: 2px solid #5792c9 !important;
}

.react-tagsinput-tag {
  background-color: #5792c9a3 !important;
  color: #fff !important;
  border-radius: 5px;
  border: 1px solid #5792c9 !important;
}
.react-tagsinput-remove {
  color: #fff !important;
}

.preview-link {
  cursor: pointer;
}

.preview-link:hover {
  color: #5792c9 !important;
}

.preview_list img {
  max-width: 100% !important;
}

.MuiTableCell-body {
  white-space: nowrap !important;
}

.scroll-top-community {
  position: fixed;
  bottom: -80px;
  font-size: 14px;
  background-color: var(--primary-color);
  color: #fff;
  border-radius: 2px;
  right: 110px;
  cursor: pointer;
  z-index: 9999;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: bottom 1s ease;
}

.ticket_card {
  background-color: #f9f9f996;
  padding: 10px;
  margin: 1px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
}

.ticket_card span {
  font-size: 14px;
}

.ticket_card_img img {
  width: 76px;
  padding: 10px;
}

.internal_card {
  background-color: #f9f9f996;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--primary-color);
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
}

.internal_card span {
  font-size: 14px;
}

.internal_card img {
  width: 76px;
}

.preview_card img {
  width: 76px;
}

.no-access-string {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.no-access-string img {
  width: 120px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.upload-button-nav-items label {
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  /* height: 76px;
  width: 76px; */
  height: 28px;
  width: 60px;
  justify-content: center;
  padding: 2px 30px;
  text-align: center !important;
}

.upload-button-nav-items svg {
  color: #fff !important;
}

.preview-nav-items {
  position: relative;
}

.preview-nav-items img {
  border-radius: 5px;
  display: inline;
  height: 30px;
  margin-right: 10px;
  width: 30px;
}

.preview-nav-items span {
  position: absolute;
  right: 3px;
  top: -21px;
  font-size: 13px;
  font-weight: 700;
  height: 20px;
  z-index: 1;
  background-color: red;
  width: 20px;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.search_btn {
  height: 40px !important;
  margin-top: 2px !important;
}
.mui-table-footer p{
margin-bottom: 0px !important;
}
.mui-table-footer{    display: flex !important;
  justify-content: space-between;}


 

  